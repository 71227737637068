import React from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import ModuleTitle from '@/business-components/module-title';
import styles from './index.module.scss';

interface ITopicContentProps {
  hasBorder?: boolean;
  title: string;
  renewText: string;
  imageUrl: string;
  linkId: string;
}

const TopicContent = ({ hasBorder, title, renewText, imageUrl, linkId }: ITopicContentProps) => (
  <a
    className={hasBorder ? classNames(styles.border, styles.topicContent) : styles.topicContent}
    href={`/topic/${linkId}`}
    target="_blank"
    rel="noreferrer"
  >
    <img className={styles.backgroundImg} src={imageUrl} alt="" />
    <div className={classNames('flex-column-nowrap', 'flex-end-start', styles.content)}>
      <div className={classNames('flex-column-nowrap', 'flex-end-start', styles.topicTextContent)}>
        <div className={styles.title}>{title}</div>
        <div className={styles.renew}>{renewText}</div>
      </div>
    </div>
  </a>
);

export default function Topic({ data }) {
  return (
    <div className={styles.topic}>
      <ModuleTitle title="专题" redirectText="查看全部" rightLink="/focus_topic" height="0" />
      <div className={classNames('flex-row-nowrap', 'flex-between-center', styles.topicSection)}>
        {data?.map((item, index) => (
          <TopicContent
            title={item.topic.name}
            renewText={`${item.topic.count || 0}篇${
              item.informations[0]?.date ? '·' + dayjs(item.informations[0]?.date).format('YYYY/MM/DD') : ''
            }`}
            imageUrl={item.topic.logo}
            linkId={item.topic.id}
            key={index}
          />
        ))}
        {/* <TopicContent title="这是一个标题" renewText="3篇·昨天更新" imageUrl="" /> */}
      </div>
    </div>
  );
}
