import React, { memo, useContext } from 'react';
import Slider from 'react-slick';
import classnames from 'classnames';
import useHref from '@/hooks/use-href';
import IndexContext from '@/context/IndexContext';
import KolCard from '../kol-card';
import ModuleTitle from '../module-title';
import styles from './index.module.scss';

const settings = {
  dots: true,
  dotsClass: classnames('slick-dots', styles['slick-dots']),
  autoplay: true,
  arrows: false,
};

const WeeklyKol: React.FC<Record<string, never>> = () => {
  const { kols } = useContext(IndexContext);
  const { href } = useHref(`/focus_star`);
  return (
    <div className={styles.weeklyKolContainer}>
      <ModuleTitle title="本周大咖" redirectText="查看全部" rightLink={href} />
      <Slider {...settings}>
        {kols?.map(item => (
          <KolCard key={item.id} className={styles.card} {...item} />
        ))}
      </Slider>
    </div>
  );
};
export default memo(WeeklyKol);
