import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useRequest } from 'ahooks';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Tag from '@/components/tag';
import ModuleTitle from '@/business-components/module-title';
import TagList from '@/business-components/tag-list';
import ReportSkeleton from '@/business-components/report-skeleton';
import { imageFormat } from '@/utils/format';
import { getReportList, increaseReportClickNum } from '@/services/report';
import styles from './index.module.scss';

enum EReportTag {
  'HOTTEST' = 'HOT', //最热
  'LATEST' = 'NEW', //最新
  'PRESELL' = '预售', //预售
  'REFERENCED' = '转载', //转载
}

interface ITopicContentProps {
  data: {
    clickTimes: number;
    createTime: string;
    id: string;
    reportId: string;
    sort: number;
    tags: { id: string; name: string }[];
    title: string;
    updateTime: string;
    cover: string;
    [key: string]: any;
  };
}

// const tagList = [
//   { id: 0, name: '全部' },
//   { id: 1, name: '餐饮零售' },
//   { id: 2, name: '美妆个护' },
//   { id: 3, name: '服饰鞋包' },
//   { id: 4, name: '家电数码' },
//   { id: 5, name: '家电数码' },
// ];

const ReportContent = ({
  data: { title, tags, clickTimes, updateTime, reportId, cover, ...rest },
}: ITopicContentProps) => {
  const handleClickUpReport = useCallback(async id => {
    try {
      await increaseReportClickNum({ id });
    } catch (error) {}
  }, []);
  const reportTag = rest.hottest
    ? 'HOTTEST'
    : rest.latest
    ? 'LATEST'
    : rest.preSell
    ? 'PRESELL'
    : rest.referenced
    ? 'REFERENCED'
    : '';
  return (
    <div className={styles.reportCard}>
      {EReportTag[reportTag] && (
        <div className={classNames(styles.reportTag, styles[reportTag])}>{EReportTag[reportTag]}</div>
      )}
      <a
        href={`/report/${reportId}/detail`}
        className={styles.imageContent}
        target="_blank"
        rel="noreferrer"
        aria-label={title}
        onClick={() => handleClickUpReport(reportId)}
      >
        {/* <img /> */}
        <LazyLoadImage
          className={styles.image}
          src={imageFormat(cover, 210, 117)}
          placeholderSrc="//images.dtcj.com/cbndata/icon/loading.gif"
          alt={title}
        />
      </a>
      <div
        className={classNames('flex-column-nowrap', 'flex-between-start', styles.bottomContent)}
        // style={{ height: height + 'px' }}
      >
        <div className={styles.header}>
          <a className={styles.title} href={`/report/${reportId}/detail`} target="_blank" rel="noreferrer">
            {title}
          </a>
          <div className={styles.tagList}>
            {tags?.map((item, index) => (
              <Tag key={index} text={item.name}></Tag>
            ))}
          </div>
        </div>
        <div className={classNames('flex-row-nowrap', 'flex-between-start', styles.bottom)}>
          <div className={classNames('flex-row-nowrap', 'flex-start-center', styles.left)}>
            <svg className="icon" aria-hidden="true">
              <use xlinkHref="#icon-visibility"></use>
            </svg>
            <span className={styles.text}>{clickTimes}</span>
          </div>
          <div className={styles.right}>
            {dayjs(updateTime).format('YYYY/MM/DD') === dayjs().format('YYYY/MM/DD')
              ? '今日'
              : dayjs(updateTime).format('YYYY/MM/DD')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Report({ dataList, tagList }) {
  const [list, setList] = useState(dataList);
  const { run, loading } = useRequest(
    // const { data, loading, loadingMore, reload, loadMore, noMore } = useRequest(
    data => {
      return getReportList({ tagId: data });
    },
    {
      onSuccess: res => {
        if (res.code === 200) {
          setList(res.data.list || []);
        }
      },
      manual: true,
    }
  );
  const [tagId, setTagId] = useState(tagList[0]?.oldTagId);
  const handleTagChange = useCallback(
    id => {
      setTagId(id);
      run(id);
    },
    [run]
  );
  // useEffect(() => {
  //   run(tagId);
  // }, [run, tagId]);
  return (
    <div className={styles.report}>
      <ModuleTitle title="报告" redirectText="查看全部" rightLink="/report?tags[]=all" height="6px">
        <TagList tagList={tagList} selectId={tagId} onChange={handleTagChange} maxWidth="80%" />
      </ModuleTitle>
      {loading ? (
        <div className={classNames('flex-row-nowrap', 'flex-between-center')}>
          <ReportSkeleton />
          <ReportSkeleton />
          <ReportSkeleton />
          <ReportSkeleton />
        </div>
      ) : (
        <>
          {list && list.length > 0 ? (
            <div className={classNames('flex-row-nowrap', 'flex-start-start', styles.reportContent)}>
              {list?.map((item, index) => (
                <ReportContent key={index} data={item} />
              ))}
            </div>
          ) : (
            <div style={{ height: '220px', lineHeight: '220px', textAlign: 'center' }}>暂无数据</div>
          )}
        </>
      )}
      {/* <div className={classNames('flex-row-nowrap', 'flex-between-center', styles.topicSection)}>
        {data?.map((item, index) => (
          <ReportContent
            title={item.topic.name}
            renewText={`${item.topic.count}篇·${dayjs(item.informations[0]?.date).format('YYYY/MM/DD')}`}
            imageUrl={item.topic.logo}
            linkId={item.topic.id}
            key={index}
          />
        ))}
      </div> */}
    </div>
  );
}
