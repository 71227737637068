import React, { memo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import classnames from 'classnames';
import dayjs from 'dayjs';
import useHref from '@/hooks/use-href';
import { imageFormat } from '@/utils/format';
import type { Activity } from '@/interface/activity';
import styles from './index.module.scss';

export type ActivityCardProps = Pick<Activity, 'activityId' | 'title' | 'cover' | 'createTime'> & {
  className?: string | undefined;
};

const ActivityCard: React.FC<ActivityCardProps> = ({
  activityId = 0,
  cover = '',
  title = '',
  createTime = '',
  className,
}) => {
  const { href } = useHref(`/activity/${activityId}/detail`);
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <div className={classnames(styles.card, className)}>
        <LazyLoadImage
          width="100%"
          // alt={title}
          src={imageFormat(cover, 110)}
          placeholderSrc="//images.dtcj.com/cbndata/icon/loading.gif"
        />
        <div className={styles.right}>
          <p className={styles.title}>{title}</p>
          <p className={styles.createTime}>{dayjs(createTime).format('YYYY/MM/DD')}</p>
        </div>
      </div>
    </a>
  );
};
export default memo(ActivityCard);
