import React, { ReactNode } from 'react';
import classNames from 'classnames';
import Divider from '@/components/divider';
import type { IDividerProps } from '@/components/divider';
import styles from './index.module.scss';

type LogoProps = {
  title?: string;
  children?: ReactNode;
  redirectText?: string;
  rightLink?: string;
  className?: string;
} & IDividerProps;

export default function ModuleTitle({
  title,
  children,
  redirectText,
  rightLink,
  className,
  ...dividerProps
}: LogoProps) {
  return (
    <div>
      <Divider height="2px" {...dividerProps} />
      <div className={classNames('flex-row-nowrap', 'flex-between-center', styles.moduleTitle, className)}>
        <div className={classNames('flex-row-wrap', 'flex-start-center')}>
          <div className={styles.title}>{title}</div>
          {children}
        </div>
        {redirectText && (
          <a
            className={classNames('flex-row-nowrap', 'flex-between-center', styles.redirectItem)}
            href={rightLink}
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.redirectText}>{redirectText}</div>
            <svg className="icon" aria-hidden="true">
              <use xlinkHref="#icon-tag_arrow_right"></use>
            </svg>
          </a>
        )}
      </div>
    </div>
  );
}
