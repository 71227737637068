import React from 'react';
import classNames from 'classnames';
import Skeleton from '@/components/skeleton';
import styles from './index.module.scss';

interface ISkeletonProps {
  width?: string;
  height?: string;
  style?: React.CSSProperties;
}

export default function InformationSkeleton() {
  const rightList: ISkeletonProps[] = [
    { width: '100%', height: '22px' },
    { width: '60%', height: '22px', style: { marginTop: '8px' } },
    { width: '70%', style: { marginTop: '20px' } },
    { width: '50%' },
    { width: '30%' },
  ];
  return (
    <div className={classNames('flex-row-nowrap', 'flex-between-start', styles.informationSkeleton)}>
      <div className={styles.leftContent}>
        <div className={styles.leftWrapper}>
          <Skeleton width="100%" height="100%" />
        </div>
      </div>
      <div className={styles.rightContent}>
        <div className={styles.rightWrapper}>
          {rightList.map((item, index) => (
            <div key={index}>
              <Skeleton {...item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
