import React from 'react';
// import classNames from 'classnames';
import Skeleton from '@/components/skeleton';
import styles from './index.module.scss';

interface ISkeletonProps {
  width?: string;
  height?: string;
  style?: React.CSSProperties;
}

export default function ReportSkeleton() {
  const rightList: ISkeletonProps[] = [
    { width: '100%', height: '20px', style: { marginTop: '18px' } },
    { width: '60%', height: '20px' },
    { width: '70%', style: { marginTop: '8px' } },
    { width: '50%' },
  ];
  return (
    <div className={styles.reportSkeleton}>
      <div className={styles.imageContent}>
        <div className={styles.imageWrapper}>
          <Skeleton width="100%" height="100%" />
        </div>
      </div>
      <div className={styles.content}>
        {rightList.map((item, index) => (
          <div key={index}>
            <Skeleton {...item} />
          </div>
        ))}
      </div>
    </div>
  );
}
