import React, { useCallback } from 'react';
import classnames from 'classnames';
import useSubscribe from '@/hooks/use-subscribe';
import Tag from '@/components/tag';
import styles from './index.module.scss';

export type TagButtonProps = {
  subEmail: string;
  tagLevelId: string;
  tagName: string;
  subscribed: boolean;
  className?: string;
};

const TagButton: React.FC<TagButtonProps> = ({
  subEmail = '',
  tagLevelId = '',
  tagName = '',
  subscribed = false,
  className,
}) => {
  const { openModal } = useSubscribe();
  const onClick = useCallback(() => {
    if (subscribed) {
      openModal({ type: 'unsubscribeTag', tagLevelId, tagName, subEmail });
    } else {
      openModal({ type: 'subscribeTag', tagLevelId, tagName });
    }
  }, [openModal, subEmail, subscribed, tagLevelId, tagName]);
  return (
    <div className={classnames(styles.container, className)}>
      <Tag text={tagName} maxWidth={120}></Tag>
      <div className={styles.operation} onClick={onClick}>
        <div className={styles.icon}>
          <svg className="icon" aria-hidden="true">
            <use xlinkHref={subscribed ? '#icon-tag_unsubscribe' : '#icon-tag_subscribe'} />
          </svg>
        </div>
        {/* <span className={styles.explanation}>{subscribed ? '取消订阅' : '订阅'}</span> */}
      </div>
    </div>
  );
};
export default TagButton;
