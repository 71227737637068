import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
const SafeSkeleton = props => {
  const [isBrower, setIsBrower] = useState(false);
  useEffect(() => {
    setIsBrower(true);
  }, []);
  return isBrower ? <Skeleton {...props} /> : <div />;
};
export default SafeSkeleton;
