import { InformationListResponseData } from '@/interface/information';
import { NextApiResponseData } from '@/interface/request';
import { nextApiRequest } from '@/utils/request';

export async function getInformationList(params) {
  return nextApiRequest.request<
    NextApiResponseData<InformationListResponseData>,
    NextApiResponseData<InformationListResponseData>
  >({
    method: 'get',
    url: '/home/informations',
    params,
  });
}
