import React, { useCallback, useContext, useMemo } from 'react';
import { Form, Field, useField } from 'react-final-form';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Input from '@/components/input';
import { subscribeTag, unsubscribeTag } from '@/services/tag';
import IndexContext from '@/context/IndexContext';
import { subscribeReport } from '@/services/report';
import { RootState } from '@/models';
import styles from './index.module.scss';

ReactModal.setAppElement('#__next');
export type SubscriptionModalProps = {
  type?: 'subscribeReport' | 'subscribeTag' | 'unsubscribeTag';
  className?: string;
};

export type SubscribeFormValues = {
  email: string;
  contentPush: boolean;
  connectEmail: boolean;
};
const emailReg =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

// const SubscribeFormInitialValues = { email: '', contentPush: true, connectEmail: true };

const Error = ({ name }) => {
  const {
    meta: { touched, error },
  } = useField(name, { subscription: { touched: true, error: true } });
  return touched && error ? <p className={styles.validateMessage}>{error}</p> : null;
};

// 订阅表单组件
const SubscribeContent = React.memo(() => {
  const { userInfo } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const { subEmail, type, tagLevelId, resetSubscribeModal, refreshTrendingTagsList } = useContext(IndexContext);
  const initialValue = {
    email: subEmail,
    contentPush: true,
    connectEmail: true,
  };
  const showConnectEmail = useMemo(() => !userInfo?.email, [userInfo]);
  const onSubmit = useCallback(
    async (values: SubscribeFormValues) => {
      try {
        const { email, connectEmail } = values;
        if (type === 'subscribeReport') {
          const { data } = await subscribeReport({ email });
          if (data.id) {
            toast.success('订阅成功');
            resetSubscribeModal();
          }
          if (showConnectEmail && connectEmail) {
            dispatch({ type: 'user/patchInfo', payload: { email } });
          }
        } else if (type === 'subscribeTag') {
          const res = await subscribeTag({ tagLevelId, subEmail: email });
          if (res.code === 200 && res.errorCode === '00000') {
            toast.success('订阅成功');
            resetSubscribeModal();
            refreshTrendingTagsList();
          }
          if (showConnectEmail && connectEmail) {
            dispatch({ type: 'user/patchInfo', payload: { email } });
          }
        }
      } catch (e) {
        console.log('error:', e);
      }
    },
    [dispatch, refreshTrendingTagsList, resetSubscribeModal, showConnectEmail, tagLevelId, type]
  );
  const validate = useCallback((values: SubscribeFormValues) => {
    const errors: { email?: string } = {};
    const { email } = values;
    if (!email) {
      errors.email = '请填写邮箱';
    } else if (!emailReg.test(email)) {
      errors.email = '邮箱格式不正确';
    }
    return errors;
  }, []);

  return (
    <Form<SubscribeFormValues>
      initialValues={initialValue}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, values }) => (
        <form className={styles.content} onSubmit={handleSubmit}>
          <label className={classnames(styles.label, styles.email)}>
            邮箱：
            <Field name="email">
              {props => (
                <Input
                  className={styles.emailInput}
                  name={props.input.name}
                  value={props.input.value}
                  placeholder="请输入您的邮箱"
                  autoComplete="off"
                  onChange={props.input.onChange}
                />
              )}
            </Field>
          </label>
          <Error name="email" />
          <label className={classnames(styles.label, styles.contentPush)}>
            <Field name="contentPush" type="checkbox">
              {props => (
                <input
                  className={styles.checkbox}
                  name={props.input.name}
                  checked={props.input.checked}
                  type="checkbox"
                  onChange={props.input.onChange}
                />
              )}
            </Field>
            我同意邮件接收内容推送
          </label>
          <p className={styles.formItemTips}>*邮件内容来自CBNData原创内容及第三方平台生产内容</p>
          {showConnectEmail && (
            <>
              <label className={classnames(styles.label, styles.connectEmail)}>
                <Field name="connectEmail" type="checkbox">
                  {props => (
                    <input
                      className={styles.checkbox}
                      name={props.input.name}
                      checked={props.input.checked}
                      type="checkbox"
                      onChange={props.input.onChange}
                    />
                  )}
                </Field>
                我同意默认绑定该邮箱
              </label>
              <p className={styles.formItemTips}>*绑定的邮箱可以在个人中心-个人设置进行修改调整</p>
            </>
          )}
          <button className={styles.submitButton} disabled={!values.contentPush}>
            确认提交
          </button>
        </form>
      )}
    />
  );
});

// 退订组件
const UnsubscribeContent = React.memo(() => {
  const { subEmail, tagLevelId, tagName, resetSubscribeModal, refreshTrendingTagsList } = useContext(IndexContext);
  const onCancelBtnClick = useCallback(() => {
    resetSubscribeModal();
  }, [resetSubscribeModal]);
  const onUnsubscribeBtnClick = useCallback(async () => {
    const res = await unsubscribeTag({ tagLevelId });
    if (res.code === 200 && res.errorCode === '00000') {
      toast.success('退订成功');
      resetSubscribeModal();
      refreshTrendingTagsList();
    }
  }, [refreshTrendingTagsList, resetSubscribeModal, tagLevelId]);
  return (
    <div className={styles.unsubscribeContent}>
      <div className={styles.unsubscribeDetail}>
        <p className={styles.labelText}>
          退订标签：<span className={styles.valueText}>{tagName}</span>
        </p>
        <p className={styles.labelText}>
          退订邮箱：<span className={styles.valueText}>{subEmail}</span>
        </p>
      </div>
      <div className={styles.unsubscribeOperation}>
        <button className={styles.cancelButton} onClick={onCancelBtnClick}>
          取消
        </button>
        <button className={styles.unsubscribeButton} onClick={onUnsubscribeBtnClick}>
          确认退订
        </button>
      </div>
      <p className={styles.unsubscribeExplanation}>
        *退订该标签只会影响该标签内容的推送，不会影响到其他订阅标签的内容推送。若订阅了其他标签，每周一上午依旧可以收到订阅邮件，请知悉
      </p>
    </div>
  );
});

const typeMap = {
  subscribeReport: {
    title: 'CBNData数据月报订阅',
    description:
      '订阅资料提交后，您将会每月收到CBNData为您整理的30余家专业研究机构的最新研究报告，以及消费领域前沿商业案例洞察',
    content: <SubscribeContent />,
  },
  subscribeTag: {
    title: 'CBNData热门标签订阅',
    description:
      '订阅资料提交后，您将会在每周周一上午收到对应标签下CBNData每周更新的最新消费资讯、研究报告以及行业活动',
    content: <SubscribeContent />,
  },
  unsubscribeTag: {
    title: 'CBNData热门标签退订',
    description: '退订后，您将不会收到该标签下CBNData每周更新的最新消费资讯、研究报告以及行业活动',
    content: <UnsubscribeContent />,
  },
};

// 头部标题+描述组件
const Header = React.memo(() => {
  const { type } = useContext(IndexContext);
  const title = useMemo(() => typeMap[type].title, [type]);
  const description = useMemo(() => typeMap[type].description, [type]);
  return (
    <>
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.description}>{description}</p>
    </>
  );
});

// 底部助手二维码组件
const Footer = React.memo(() => {
  return (
    <div className={styles.footer}>
      <p className={styles.text}>
        想即时了解更多CBNData内容动态？有其他问题咨询？欢迎添加CBNData社群小助手DD-4，也欢迎申请加入CBNData社群
      </p>
      <img
        src="https://assets-oss.cbndata.com/cbndata/assets/Fjuoi7Hd5AjzCv3hkqRHPM0wtDrP.png"
        alt="小助手微信二维码"
      />
    </div>
  );
});

const SubscriptionModal: React.FC<SubscriptionModalProps> = ({ type = 'subscribe', className }) => {
  const { isOpen, setSubscribeModal } = useContext(IndexContext);
  const closeModal = useCallback(() => {
    setSubscribeModal({ isOpen: false });
  }, [setSubscribeModal]);

  const content = useMemo(() => typeMap[type].content, [type]);

  return (
    <ReactModal
      isOpen={isOpen}
      preventScroll
      onRequestClose={closeModal}
      overlayClassName={styles.ReactModal__Overlay}
      className={classnames(styles.ReactModal__Content, className)}
    >
      <svg className={classnames('icon', styles.closeIcon)} aria-hidden="true" onClick={closeModal}>
        <use xlinkHref="#icon-tag_unsubscribe" />
      </svg>
      <Header />
      {content}
      <Footer />
    </ReactModal>
  );
};
export default SubscriptionModal;
