import React, { memo } from 'react';
import classnames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useHref from '@/hooks/use-href';
import { imageFormat } from '@/utils/format';
import type { KOL } from '@/interface/kol';
import styles from './index.module.scss';

type KolCardProps = KOL & {
  className?: string;
};

const KolCard: React.FC<KolCardProps> = ({ informationId, name, note, image, summary, className }) => {
  const { href } = useHref(`/information/${informationId}`);
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <div className={classnames(styles.card, className)}>
        <div className={styles.left}>
          <div>
            <p className={styles.name}>{name}</p>
            <p className={styles.note}>{note}</p>
          </div>
          <LazyLoadImage
            width="100%"
            alt={name}
            src={imageFormat(image, 100)}
            placeholderSrc="//images.dtcj.com/cbndata/icon/loading.gif"
          />
        </div>
        <div className={styles.right}>
          <p className={styles.summary}>{summary}</p>
          <img
            className={styles.speechBalloon}
            src="//assets-oss.cbndata.com/cbndata-web/icons/Fl4UbGD9z6jGzdMHhchnlBQvcCrK.png"
            alt="summary"
          />
        </div>
      </div>
    </a>
  );
};

export default memo(KolCard);
