import { NextApiResponseData } from '@/interface/request';
import { TagListResponse, UnsubscribeTagEmailResponseData } from '@/interface/tag';
import { nextApiRequest } from '@/utils/request';

export async function getTagList(params) {
  return nextApiRequest.request<any>({
    method: 'get',
    url: '/home/v1/tags',
    params,
  });
}

export async function getTrendingTagsList() {
  return nextApiRequest.request<NextApiResponseData<TagListResponse>, NextApiResponseData<TagListResponse>>({
    method: 'get',
    url: '/user/v1/sub_tags',
  });
}

export async function subscribeTag(data: { subEmail: string; tagLevelId: string }) {
  return nextApiRequest.request<NextApiResponseData<Record<string, never>>, NextApiResponseData<Record<string, never>>>(
    {
      method: 'post',
      url: '/user/v1/usersubscribetag',
      data,
    }
  );
}
export async function unsubscribeTag(data: { tagLevelId: string }) {
  return nextApiRequest.request<NextApiResponseData<Record<string, never>>, NextApiResponseData<Record<string, never>>>(
    {
      method: 'delete',
      url: '/user/v1/usersubscribetag',
      data,
    }
  );
}

export async function unsubscribeTagEmail(data: { email: string; unsubToken: string }) {
  return nextApiRequest.request<
    NextApiResponseData<UnsubscribeTagEmailResponseData>,
    NextApiResponseData<UnsubscribeTagEmailResponseData>
  >({
    url: '/user/v1/user_sub_tags/unsub_all',
    method: 'delete',
    data,
  });
}
