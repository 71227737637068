import { ReportListResponseData, ReportSubscriptionResponseData } from '@/interface/report';
import { NextApiResponseData } from '@/interface/request';
import axios, { nextApiRequest } from '@/utils/request';

// 获取报告列表
export async function getReportList(params?) {
  return nextApiRequest.request<
    NextApiResponseData<ReportListResponseData>,
    NextApiResponseData<ReportListResponseData>
  >({
    method: 'get',
    url: '/content/portal/report',
    params,
  });
}

// 订阅数据月报
export async function subscribeReport(data: { email: string; origin?: string; content?: string }) {
  return axios.request<ReportSubscriptionResponseData, ReportSubscriptionResponseData>({
    url: '/v2/email_subscriptions',
    method: 'post',
    data: {
      origin: 'official_website',
      content: ['商业研究', '电商运营'],
      ...data,
    },
  });
}

// 退订数据月报
export async function unsubscribeMonthlyReportEmail(data: { email: string }) {
  return axios.request<Record<string, null>, Record<string, null>>({
    url: '/v2/email_subscriptions/unsubscribe',
    method: 'post',
    data,
  });
}

export async function increaseReportClickNum(data) {
  return nextApiRequest.request<any>({
    method: 'put',
    url: '/content/portal/report/click',
    data,
  });
}
