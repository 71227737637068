import React, { useCallback } from 'react';
import useSubscribe from '@/hooks/use-subscribe';
import styles from './index.module.scss';

const MonthlyReportSubscription: React.FC<Record<string, never>> = React.memo(() => {
  const { openModal } = useSubscribe();
  const onSubscribeBtnClick = useCallback(() => {
    openModal({ type: 'subscribeReport' });
  }, [openModal]);

  return (
    <div className={styles.monthlyReportSubscriptionContainer}>
      <img
        className={styles.bgImg}
        src="//assets-oss.cbndata.com/cbndata-web/Downloads/FulyPP5O0YlHX1MnDl-E04Fo-Mg2.png"
        alt="monthly-report-subscription"
      />
      <div className={styles.content}>
        <p className={styles.title}>订阅CBNData数据月报</p>
        <p className={styles.description}>涵盖20+研究机构精华内容，每月为你整理全网最有价值的大数据报告。</p>
        <button className={styles.subscribeButton} onClick={onSubscribeBtnClick}>
          立即订阅
        </button>
      </div>
    </div>
  );
});
export default MonthlyReportSubscription;
