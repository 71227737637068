import React, { ReactNode, useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './index.module.scss';
interface TagProps {
  text?: string;
  maxWidth?: number;
  children?: ReactNode;
}

export default function Tag({ text, maxWidth }: TagProps) {
  const [toolTipProps, setToolTipProps] = useState({});
  const ref = useRef<HTMLAnchorElement>(null);
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  useEffect(() => {
    if (maxWidth) {
      const { width } = ref.current.getBoundingClientRect();
      width >= maxWidth && setToolTipProps({ 'data-for': 'tags', 'data-tip': text });
    }
  }, [maxWidth, text]);
  return (
    <a
      ref={ref}
      className={styles.tag}
      style={maxWidth ? { maxWidth } : {}}
      href={`/search?query=${text}`}
      target="_blank"
      rel="noreferrer"
      {...toolTipProps}
    >
      <span className={styles.tips}>#</span>
      <span className={styles.text}>{text}</span>
    </a>
  );
}
