import React from 'react';
import { Space } from 'antd';
// import classNames from 'classnames';
import styles from './index.module.scss';

export interface ITagSearch {
  homeTagId: string;
  tagLevelId: string;
  oldTagId: string;
  tagLevelName: string;
  locationType: string;
  sort: number;
}

export default function TagsComponent({
  tagList,
}: {
  tagList: { tagLevelName: string; key?: number; link?: string }[];
  size?: number[];
}) {
  return (
    // <div className={classNames('flex-row-wrap', 'flex-start-center', styles.tagsList)}>
    <Space size={[8, 6]} wrap className={styles.tagsList}>
      {tagList?.slice(0, 12).map((item, index) => (
        <a
          key={index}
          href={`/search?query=${item.tagLevelName}`}
          target="_blank"
          rel="noreferrer"
          className={styles.tagItem}
          // onClick={e => {
          //   e.preventDefault();
          //   throw new Error();
          // }}
        >
          # {item.tagLevelName}
        </a>
      ))}
    </Space>
    // </div>
  );
}
