import { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IndexContext from '@/context/IndexContext';
import type { SubscribeModalValue } from '@/context/IndexContext';
import { USER_TOKEN } from '@/constants/global';
import { RootState } from '@/models';

export default function useSubscribe() {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const { setSubscribeModal } = useContext(IndexContext);
  const openModal = useCallback(
    (value: Partial<Omit<SubscribeModalValue, 'isOpen'>>) => {
      if (!localStorage.getItem(USER_TOKEN)) {
        dispatch({ type: 'modal/openUserLoginModal', payload: 'login' });
      } else {
        const { subEmail, tagLevelId, tagName, type } = value;
        setSubscribeModal({
          subEmail: /^unsubscribe/.test(type) ? subEmail : userInfo?.email || subEmail,
          tagLevelId,
          tagName,
          type,
          isOpen: true,
        });
      }
    },
    [dispatch, setSubscribeModal, userInfo?.email]
  );
  return {
    openModal,
  };
}
