import React, { memo, useContext } from 'react';
import IndexContext from '@/context/IndexContext';
import useHref from '@/hooks/use-href';
import ActivityCard from '../activity-card';
import ModuleTitle from '../module-title';
import styles from './index.module.scss';

const RecommendActivity: React.FC<Record<string, never>> = () => {
  const { href } = useHref(`/activity`);
  const { activity } = useContext(IndexContext);
  return (
    <div>
      <ModuleTitle title="推荐活动" redirectText="查看全部" rightLink={href} />
      {activity.map(item => (
        <ActivityCard key={item.id} className={styles.card} {...item} />
      ))}
    </div>
  );
};
export default memo(RecommendActivity);
