import React, { useContext } from 'react';
import classnames from 'classnames';
import Slider from 'react-slick';
import IndexContext from '@/context/IndexContext';
import TagButton from '../tag-button';
import ModuleTitle from '../module-title';
import styles from './index.module.scss';

const settings = {
  dots: true,
  dotsClass: classnames('slick-dots', styles['slick-dots']),
  autoplay: false,
  arrows: false,
};

const TagsSubscription: React.FC<Record<string, never>> = () => {
  const { trendingTags } = useContext(IndexContext);
  return (
    <div className={styles.container}>
      <ModuleTitle title="标签订阅" />
      <Slider {...settings}>
        {trendingTags?.map((item, index) => (
          <div key={index}>
            <div className={styles.trendingTagsContainer}>
              {item.map(tagItem => (
                <div key={tagItem.tagLevelId} className={styles.tagButtonWrapper}>
                  <TagButton
                    subEmail={tagItem.subEmail}
                    tagName={tagItem.tagName}
                    subscribed={!!tagItem.subEmail}
                    tagLevelId={tagItem.tagLevelId}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default TagsSubscription;
