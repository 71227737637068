import { useMemo } from 'react';
import { IS_DAILY, IS_DEV, IS_PROD } from '@/constants/env';
const host = IS_DEV || IS_DAILY ? 'staging.cbndata.com' : IS_PROD ? 'www.cbndata.com' : '';

export default function useHref(path) {
  const href = useMemo(() => `https://${host}${path}`, [path]);
  return {
    href,
  };
}
