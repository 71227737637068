import React, { useState, useCallback, useEffect } from 'react';
// import classNames from 'classnames';
import ReactLoading from 'react-loading';
import { useRequest, useSetState } from 'ahooks';
import ModuleTitle from '@/business-components/module-title';
import TagList from '@/business-components/tag-list';
import NormalCard from '@/components/normal-card';
import Button from '@/components/button';
import Divider from '@/components/divider';
import InformationSkeleton from '@/business-components/information-skeleton';
import { getInformationList } from '@/services/information';
import styles from './index.module.scss';

export default function Information({ children, dataList, tagList }) {
  const [params, setParmas] = useSetState({ pageIndex: 1, pageSize: 20, oldTagId: tagList[0]?.oldTagId });
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(dataList);
  const [total, setTotal] = useState(0);
  const { run, loading } = useRequest(
    // const { data, loading, loadingMore, reload, loadMore, noMore } = useRequest(
    data => {
      return getInformationList(data);
    },
    {
      onSuccess: res => {
        if (res.code === 200) {
          setData(params.pageIndex === 1 ? res.data?.list || [] : [...data, ...res.data?.list]);
          setTotal(res.data?.meta?.total_count || 0);
          setIsLoading(false);
        }
      },
      manual: true,
    }
  );
  const handleTagChange = useCallback(
    id => {
      setParmas({ pageIndex: 1, oldTagId: id });
    },
    [setParmas]
  );
  const handleLoadMore = useCallback(() => {
    if (isLoading) return;
    setParmas({ pageIndex: params.pageIndex + 1 });
    setIsLoading(true);
  }, [isLoading, params.pageIndex, setParmas]);
  useEffect(() => {
    run(params);
  }, [params, run]);
  const handleClickUpReport = useCallback(async () =>
    // data
    {
      // try {
      //   await upReportClick({ id: data.id });
      // } catch (error) {}
    }, []);
  return (
    <div className={styles.information}>
      <ModuleTitle title="看点" redirectText="查看全部" rightLink="/information" height="6px">
        <TagList tagList={tagList} selectId={params.oldTagId} onChange={handleTagChange} />
      </ModuleTitle>
      {loading ? (
        <>
          <InformationSkeleton />
          <InformationSkeleton />
          <InformationSkeleton />
        </>
      ) : (
        <>
          {data?.length === 0 && (
            <div style={{ height: '275px', lineHeight: '275px', textAlign: 'center' }}>暂无数据</div>
          )}
          {data.slice(0, 3).map((item, index) => (
            <NormalCard key={index} data={item} itemCardClick={handleClickUpReport} />
          ))}
        </>
      )}
      {children}
      {data.slice(3, data.length).map((item, index) => (
        <NormalCard key={index} data={item} itemCardClick={handleClickUpReport} />
      ))}
      {data?.length < total ? (
        <div className={styles.loadMore}>
          <Button
            width={104}
            height={32}
            style={{ margin: '20px 0 44px', lineHeight: '30px' }}
            onClick={handleLoadMore}
          >
            {isLoading ? (
              <ReactLoading type="spinningBubbles" width="20px" height="20px" className={styles.loadMoreBtn} />
            ) : (
              '加载更多'
            )}
          </Button>
        </div>
      ) : total > 0 ? (
        <div className={styles.loadComplete}>
          <Divider height="1px" color="#333" text="已加载到底" />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
